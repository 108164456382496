import { reactive } from "vue";
import { RegisterFormData } from "../../../../../shared/protocol/auth/Register";
import { LoginFormData, LoginResponse } from "../../../../../shared/protocol/auth/Login";
import {ValidateFormData, ValidateResponse} from "../../../../../shared/protocol/auth/Validate";
import { ServerResponse } from "../../../../../shared/protocol/generic/ServerResponse";
import Client from "../Client";
import { Preferences } from "@capacitor/preferences";
import router from "@/router";
import AccountClient from "./AccountClient";

export default new class AuthClient {

    public readonly state = reactive({
        loggedIn: false,
        token: '',
        user: ''
    });


    public finishedInit: Promise<void>;

    constructor() {
        this.finishedInit = new Promise((resolve) => {
            Preferences.get({ key: 'auth' }).then(result => {
                if (result.value !== null) {
                    const parsed = JSON.parse(result.value);
                    if (parsed.user !== undefined && parsed.token !== undefined) {
                        this.state.user = parsed.user;
                        this.state.token = parsed.token;
                        this.state.loggedIn = true;
                    }
                }
                resolve();
            });
        });
        (window as any).authClient = this;
    }

    async register(req: RegisterFormData): Promise<ServerResponse> {
        return await Client.request('auth:register', req);
    }

    async login(req: LoginFormData): Promise<LoginResponse> {
        const response: LoginResponse = await Client.request('auth:login', req);
        if (response.success && response.token !== undefined && response.user !== undefined) {
            this.state.loggedIn = true;
            this.state.token = response.token;
            this.state.user = response.user;
            await Preferences.set({ key: 'auth', value: JSON.stringify({ token: response.token, user: response.user }) });

        }
        return response;
    }

    async validate(req: ValidateFormData): Promise<ValidateResponse> {
        return await Client.request('auth:validate', req);
    }

    async logout() {
        await Preferences.remove({key: 'auth'});
        AccountClient.state.data = undefined;
        router.push('/login');
    }
}