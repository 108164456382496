import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global css */
import './theme/global.scss';

/* Sweetalert */
import VueSweetalert2 from 'vue-sweetalert2';

/* Google Login */


/* Mapbox */
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = "pk.eyJ1IjoiZGF2aWR0cmF1bSIsImEiOiJjbDd6d3RuaXMwMGtoM3J0OWN4aTk2d2Y1In0.uZn1ISsPgncvRQxvZNT79w";

const app = createApp(App)
  .use(IonicVue, {mode: 'ios'})
  .use(router)
  .use(VueSweetalert2);
  
router.isReady().then(() => {
  app.mount('#app');
});