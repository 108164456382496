import LanguageService from "./LanguageService";

export type LangWordMap = {
    [key: string]: Array<string>
}

export class LangModel {
    constructor(private readonly words: LangWordMap) {
    }

    t(key: string): string {
        if(this.words[key] !== undefined) {
            return this.words[key][LanguageService.current.value.id];
        } else {
            return key;
        }
    }
}