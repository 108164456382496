import { Ref, ref } from "vue";
import AuthClient from '@/lib/client/modules/AuthClient';
import AccountClient from '@/lib/client/modules/AccountClient';
import { StorageAdapter } from '../storage/StorageAdapter';

export interface Language {
    id: number;
    key: 'en' | 'de';
    icon: string;
    label: string;
}

export default new class LanguageService {
    public readonly languages: { [key: string]: Language } = {
        de: {
            id: 0,
            key: 'de',
            icon: require('@/assets/icon/flag_german.svg'),
            label: 'Deutsch'
        },
        en: {
            id: 1,
            key: 'en',
            icon: require('@/assets/icon/flag_england.svg'),
            label: 'English'
        }
    }
    public readonly current: Ref<Language> = ref(this.languages.en);
    public readonly languageIcon: Ref<string> = ref('');

    private readonly storage = new StorageAdapter({module: 'language'});

    constructor() {
        (window as any).languageService = this;
        this.storage.get('language').then(value => {
            if (value !== null) {
                this.setLanguage(this.languages[value], false);
            } else {
                if(navigator.language.toLowerCase().startsWith('de')) {
                    this.setLanguage(this.languages['de'], false);
                } else {
                    this.setLanguage(this.languages['en'], false);
                }
            }
        });
    }

    async setLanguage(lang: Language, save = true) {
        this.current.value = lang;
        if (save) {
            await this.storage.set('language', lang.key );
            if(AuthClient.state.loggedIn) {
                await AccountClient.updateLanguage(lang.key);
            }
        }
    }

    t(words: string[]) {
        return words[this.current.value.id];
    }
}