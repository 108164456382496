import { Preferences } from "@capacitor/preferences";

interface StorageAdapterConfig {
    module: string;
}

export class StorageAdapter {

    constructor(private readonly config: StorageAdapterConfig) {}

    buildKey(key: string): string {
        return `${this.config.module}.${key}`;
    }

    async get(key: string, defaultValue: string | null = null): Promise<string | null> {
        key = this.buildKey(key);
        const value = await Preferences.get({key});
        return value.value !== null ? value.value : defaultValue;
    }

    async set(key: string, value: string) {
        key = this.buildKey(key);
        await Preferences.set({key,value});
    }

    async setMulti(data: {[key: string]: any}) {
        for(const key in data) {
            if(data[key] !== undefined && data[key] !== null) {
                await this.set(key, data[key]);
            }
        }
    }
}