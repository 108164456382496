import Swal, { SweetAlertIcon } from 'sweetalert2';

export default new class AlertService {

    simple(title: string, message: string, mode: SweetAlertIcon) {
        Swal.fire({
            title: title,
            html: message,
            icon: mode,
            confirmButtonColor: "#035a69",
            heightAuto: false,
          });
    }

}