import { Capacitor } from "@capacitor/core";
import { reactive } from "vue"
import { modalController, popoverController } from '@ionic/vue';


export default new class LayoutService {
    public readonly status = reactive({
        mobile: this.checkMobile(),
        isApp: Capacitor.getPlatform() !== 'web'
    });

    constructor() {
        matchMedia('(max-width: 1000px)').addEventListener('change', (ev) => {
            this.status.mobile = ev.matches;
        });
    }

    checkMobile(): boolean {
        return window.innerWidth < 1000;
    }

    getModalController(): typeof modalController | typeof popoverController {
        return this.status.mobile ? modalController : popoverController;
    }
}