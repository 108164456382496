import { reactive } from 'vue';
import { AccountData, AccountInfo } from "../../../../../shared/protocol/account/Account"
import { ServerResponse } from '../../../../../shared/protocol/generic/ServerResponse';
import Client from "../Client";
import { UpdateImageForm } from '../../../../../shared/protocol/account/AccountSettings';
import { Preferences } from '@capacitor/preferences';
import { AccountSearchForm, AccountSearchResponse } from '../../../../../shared/protocol/account/AccountSocial';
import {Lang} from "../../../../../server/node_modules/@prisma/client";

export interface AccountClientState {
    data?: AccountData,
    profileImageRandomSeed?: string
}

export default new class AccountClient {

    public readonly state: AccountClientState = reactive({
        data: undefined,
        profileImageRandomSeed: undefined
    });

    constructor() {
        Preferences.get({ key: 'profile_image_random_seed' }).then(result => {
            if (result.value !== null) {
                this.state.profileImageRandomSeed = result.value;
            } else {
                this.updateProfileImageRandomSeed();
            }
        });
    }

    async updateProfileImageRandomSeed() {
        this.state.profileImageRandomSeed = `${Math.floor(Math.random() * 100000)}`;
        await Preferences.set({ key: 'profile_image_random_seed', value: this.state.profileImageRandomSeed });
    }

    async loadData(force?: boolean) {
        if (force || this.state.data === undefined) {
            const response = await Client.request('account:data');
            if (response.success) {
                this.state.data = response.data;
            }
            return response;
        }
    }

    async updateImage(form: UpdateImageForm): Promise<ServerResponse> {
        const response = await Client.request('account:update-image', form);
        await this.updateProfileImageRandomSeed();
        if(this.state.data) {
            this.state.data.image = form.image !== undefined;
        }
        return response;
    }

    getProfileImageUrl(userId: string) {
        if(this.state.data && userId === this.state.data.id) {
            return `${Client.randomServer()}/profile_image/${userId}?r=${this.state.profileImageRandomSeed}`;
        } else {
            return `${Client.randomServer()}/profile_image/${userId}`;
        }
    }

    getAccountProfileImageUrl(account: AccountInfo) {
        if(account.image) {
            return this.getProfileImageUrl(account.id);
        } else {
            return require('@/assets/icon/user.png');
        }
    }

    getProfileImage() {
        if (this.state.data && this.state.data.image) {
            return this.getProfileImageUrl(this.state.data.id);
        } else {
            return require('@/assets/icon/user.png');
        }
    }

    async searchAccounts(form: AccountSearchForm): Promise<AccountSearchResponse> {
        return await Client.request('account:search', form);
    }

    async updateLanguage(lang: Lang): Promise<ServerResponse> {
        return await Client.request('account:update-language', lang);
    }
}