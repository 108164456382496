import { ServerRequest } from "@/../../../shared/protocol/generic/ServerRequest";
import { ServerResponse } from "@/../../../shared/protocol/generic/ServerResponse";
import { reactive } from "vue";
import AlertService from "../alert/AlertService";
import LanguageService from "../lang/LanguageService";
import AuthClient from "./modules/AuthClient";
import { toastController } from '@ionic/vue';

export interface RequestOptions {
    silentError?: boolean
}

export default new class Client {

    public readonly SERVER_COUNT = 1;
    public readonly LOCAL_PORT = 3700;
    public readonly CONNECTION_TIMEOUT = 10000;

    public readonly state = reactive({
        loading: false,
        online: window.navigator.onLine
    });

    constructor() {
        window.addEventListener('online', () => { this.onChangeOnline(true); });
        window.addEventListener('offline', () => { this.onChangeOnline(false); });
    }

    async request(action: string, data?: any, options?: RequestOptions): Promise<ServerResponse> {
        this.state.loading = true;
        const request: ServerRequest = { action, data: data ?? {}, origin: location.origin, lang: LanguageService.current.value.key };
        if (AuthClient.state.loggedIn) {
            request.auth = {
                user: AuthClient.state.user,
                token: AuthClient.state.token
            };
        }
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), this.CONNECTION_TIMEOUT);
        try {
            const response = await fetch(`${this.randomServer()}/req`, {
                method: 'POST',
                body: JSON.stringify(request),
                signal: controller.signal,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            clearTimeout(id);
            const result = (await response.json()) as ServerResponse;
            if (result.success === false) {
                if (result.error !== undefined) {
                    if (!(options !== undefined && options.silentError)) {
                        AlertService.simple('Error', result.error[LanguageService.current.value.id], 'error');
                    }
                } else {
                    if (result.loginInvalid) {
                        AuthClient.logout();
                    }
                }
            }
            this.state.loading = false;
            return result;
        } catch (ex: any) {
            clearTimeout(id);
            console.error(ex);
            this.state.loading = false;
            if (this.state.online) {
                AlertService.simple('Error',
                    `${LanguageService.t(['Ein Serverfehler ist aufgetreten. Bitte versuche es später erneut.', 'A server error occured. Please try again later.'])}<br><br>${ex.message ?? ''}`,
                    'error');
            } else {
                const toast = await toastController.create({message: LanguageService.t(['Es besteht keine Internetverbindung.', 'There is no internet connection.']), color: 'warning', duration: 2000});
                await toast.present();
            }
            return {
                success: false
            }
        }
    }

    async get(url: string): Promise<any> {
        this.state.loading = true;
        const response = await fetch(url);
        const result = await response.json();
        this.state.loading = false;
        return result;
    }

    randomServer(): string {
        if (window.location.hostname === 'localhost') {
            return `http://localhost:${this.LOCAL_PORT}`;
        } else {
            return `https://s${Math.floor(Math.random() * this.SERVER_COUNT)}.runtime.fit`;
        }
    }

    private async onChangeOnline(online: boolean) {
        this.state.online = online;
        if (!online) {
            const toast = await toastController.create({ message: LanguageService.t(["Du bist jetzt offline.", "You are now offline."]), duration: 3000, color: 'warning' });
            await toast.present();
        }
    }
}