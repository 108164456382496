import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/home/HomePage.vue';
import LanguageService from '@/lib/lang/LanguageService';
import AuthClient from '@/lib/client/modules/AuthClient';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { title: ['Startseite', 'Home'] },
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: ['Anmelden', 'Login'] },
    component: () => import('@/views/login/LoginPage.vue')
  },
  {
    path: '/map',
    name: 'Map',
    meta: { title: ['Veranstaltungskarte', 'Event map'] },
    component: () => import('@/views/map/MapPage.vue')
  },
  {
    path: '/upgrade',
    name: 'Plans',
    meta: { title: ['Upgrades', 'Upgrades'] },
    component: () => import('@/views/plans/PlanSelectPage.vue')
  },
  {
    path: '/info',
    name: 'Info',
    meta: { title: ['Informationen', 'Information'] },
    component: () => import('@/views/information/InformationPage.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { title: ['Kontakt', 'Contact'] },
    component: () => import('@/views/contact/ContactPage.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/account/AccountPage.vue'),
    redirect: '/account/profile',
    meta: { title: ['Account', 'Account'], protected: true },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: { title: ['Profil', 'Profile'] },
        component: () => import('@/views/account/profile/ProfilePage.vue')
      },
      {
        path: 'event/new',
        name: 'Create Event',
        meta: { title: ['Neue Veranstaltung', 'Create event'] },
        props: { edit: false },
        component: () => import('@/views/account/create_event/CreateEventPage.vue')
      },
      {
        path: 'event/edit/:eventid',
        name: 'Edit Event',
        meta: { title: ['Veranstaltung bearbeiten', 'Edit event'] },
        props: { edit: true },
        component: () => import('@/views/account/create_event/CreateEventPage.vue')
      },
      {
        path: 'event/manage',
        name: 'Manage events',
        meta: { title: ['Veranstaltungen verwalten', 'Manage events'] },
        component: () => import('@/views/account/manage_events/ManageEventsPage.vue')
      },
      {
        path: 'event/print/:eventid',
        name: 'Print finish codes',
        meta: { title: ['Zielcodes drucken', 'Print finish codes'] },
        component: () => import('@/views/account/manage_events/components/PrintFinishCodes.vue')
      },
      {
        path: 'help/scan',
        name: 'Scan events',
        meta: { title: ['Helfen als Scanner', 'Help as scanner'] },
        component: () => import('@/views/account/scan_event/ScanEventListPage.vue')
      },
      {
        path: 'help/measure',
        name: 'Measure event time',
        meta: { title: ['Helfen als Zeitnehmer', 'Help as timekeeper'] },
        component: () => import('@/views/account/measure_event/MeasureEventTimePage.vue')
      },
      {
        path: 'social/search',
        name: 'Search athletes',
        meta: { title: ['Sportler suchen', 'Search athlethes'] },
        component: () => import('@/views/account/social_search/SocialSearchPage.vue')
      }
    ]
  },
  {
    path: '/event/:eventid',
    name: 'Event',
    meta: { title: ['Veranstaltungsinformationen', "Event details"] },
    component: () => import('@/views/event/EventPage.vue')
  },
  {
    path: '/results/:eventid',
    name: 'Results',
    meta: { title: ['Ergebnisse', 'Results'] },
    component: () => import('@/views/results/EventResultsPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const noLoginRequired = true;

router.beforeEach((rec) => {
  AuthClient.finishedInit.then(() => {
    if (rec.meta.protected && !AuthClient.state.loggedIn && !noLoginRequired) {
      router.push(`/login#redirect:${rec.fullPath}`);
    } else {
      if (rec.meta !== undefined && rec.meta.title !== undefined) {
        window.document.title = `RunTime | ${LanguageService.t(rec.meta.title as string[])}`;
      } else {
        window.document.title = 'RunTime';
      }
    }
  });
});

export default router
